import { DrawFeature, DrawCustomModeThis, DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import type { Feature, Point } from '@turf/turf';

export enum DRAW_SHAPE_DATA_TYPE {
  WKT = 'WKT',
  GEOJSON = 'GEOJSON',
}

export enum GEOMETRY_TYPE {
  polygon = 'Polygon',
  multiPolygon = 'MultiPolygon',
  lineString = 'LineString',
  multiLineString = 'MultiLineString',
  point = 'Point',
  multiPoint = 'MultiPoint',
}

export enum DRAWING_MODES {
  polygon = 'draw_polygon',
  rectangle = 'draw_rectangle',
  freeHand = 'draw_free_hand',
  lineDistance = 'draw_line_distance',
  circle = 'draw_circle',
  point = 'draw_point',
  static = 'static',

  simpleSelect = 'simple_select',
  directSelect = 'direct_select',
  selectPoints = 'draw_select_points',
  carveOut = 'draw_carve_out',
}

export enum SHAPE {
  Polygon = 'Polygon',
  Circle = 'Circle',
  Rectangle = 'Rectangle',
  LineDistance = 'LineDistance',
  Point = 'Point',
}

export enum SHAPE_GEO_META {
  FEATURE = 'feature',
  MIDPOINT = 'midpoint',
  VERTEX = 'vertex',
  RADIUS = 'radius',
  POINTER_POSITION = 'pointerPosition',
}

export enum SHAPE_ACTIVE_STATES {
  ACTIVE = 'true',
  INACTIVE = 'false',
}

export enum SHAPE_ANNOTATION {
  ADD_SHAPE = 'ADD_SHAPE',
  REMOVE_SHAPE = 'REMOVE_SHAPE',
  REMOVE_ALL_SHAPES = 'REMOVE_ALL_SHAPES',
  BULK_UPDATE = 'BULK_UPDATE',

  UPDATE_GEOMETRY = 'UPDATE_GEOMETRY',

  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  UPDATE_MUTED = 'UPDATE_MUTED',

  UPDATE_STROKE_COLOR = 'UPDATE_STROKE_COLOR',
  UPDATE_FILL_COLOR = 'UPDATE_FILL_COLOR',
  UPDATE_STROKE_WIDTH = 'UPDATE_STROKE_WIDTH',

  UPDATE_LABEL_NAME = 'UPDATE_LABEL_NAME',
  UPDATE_LABEL_VISIBILITY = 'UPDATE_LABEL_VISIBILITY',
  UPDATE_LABEL_FONT_SIZE = 'UPDATE_LABEL_FONT_SIZE',
  UPDATE_LABEL_FONT_COLOT = 'UPDATE_LABEL_FONT_COLOT',
  UPDATE_LABEL_HALO_COLOR = 'UPDATE_LABEL_HALO_COLOR',
  UPDATE_LABEL_NUDGE = 'UPDATE_LABEL_NUDGE',

  UPDATE_SIMPLIFY = 'UPDATE_SIMPLIFY',
  UPDATE_BUFFER = 'UPDATE_BUFFER',
  UPDATE_ROTATE = 'UPDATE_ROTATE',
  UPDATE_SCALE = 'UPDATE_SCALE',
}

export interface IAdvancedShapeSettings {
  simplify: number;
  buffer: number;
  rotate: number;
  scale: number;
}

export type TShapeGeometry =
  | GeoJSON.Point
  | GeoJSON.MultiPoint
  | GeoJSON.LineString
  | GeoJSON.MultiLineString
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon;

export type TShapeGeometryType = TShapeGeometry['type'];

export interface ILabelProperties {
  name: string;
  showLabel: boolean;
  muted: boolean;
  invalid: boolean;

  font: string;
  fontSize: number;
  fontColor: string;
  fontOpacity: number;

  haloColor: string;
  haloOpacity: number;
  position: [number, number];
}

export interface IShapeProperties {
  muted: boolean;
  invalid: boolean;
  nonRepairable: boolean;

  fillColor: string;
  fillOpacity: number;

  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  type: SHAPE;
}
export type TShapeProperty = keyof IShapeProperties;

export interface IShape {
  id: string;
  usershapeid?: number | undefined;
  type: 'Feature';
  settings: IAdvancedShapeSettings;
  properties: IShapeProperties;
  labelProperties: ILabelProperties;
  geometry: TShapeGeometry;
}

export interface IShapeSelectedVertices {
  shapeId: string;
  vertices: Feature<Point>[];
}

export interface IHistoryAnnotation {
  type: string;
  replaceable?: boolean;
  override?: boolean;
}

export interface IShapeAnnotation extends IHistoryAnnotation {
  type: SHAPE_ANNOTATION;
  replaceable?: boolean;
  override?: boolean;
}

export interface IEventShape {
  id: string;
  type: 'Feature';
  geometry: TShapeGeometry;
  properties: Record<string, unknown>;
}

// Shape Custom Modes
export interface IBaseShapeSource {
  type: 'geojson';
  data: GeoJSON.FeatureCollection;
}

export interface IShapeBaseEvent {
  lngLat: { lat: number; lng: number };
}

export interface IBaseShapeProperties {
  id: string;
  active: SHAPE_ACTIVE_STATES;
  meta: SHAPE_GEO_META;
  type: SHAPE;
}

export interface ShapeSearchInfo {
  usershapeid: number;
  ownername: string;
  label: string;
  shapeicon: string;
  userid: number;
}

export interface IBaseShape<T, K> {
  id: string;
  ctx: { map: mapboxgl.Map; api: { add: (feature: T) => string } };

  isValid: () => boolean;
  toGeoJSON: () => K;

  addCoordinate: (position: number | string, lng: number, lat: number) => void;
  updateCoordinate: (position: number | string, lng: number, lat: number) => void;
  removeCoordinate: (position: number | string) => void;
}

export interface IRegtangleShapeState {
  rectangle: IBaseShape<GeoJSON.Feature<GeoJSON.Polygon>, GeoJSON.Feature<GeoJSON.Polygon>>;
  startPoint: [number, number];
  endPoint: [number, number];
}

export interface ILineShapeState {
  line: IBaseShape<GeoJSON.Feature<GeoJSON.Polygon>, GeoJSON.Feature<GeoJSON.LineString>>;
  currentVertexPosition: number;
  direction: string;
}

interface IShapeFeature extends GeoJSON.Feature<GeoJSON.Polygon, IBaseShapeProperties> {
  id: string;
  coordinates: { length: number }[];
  incomingCoords: (coordinates: number[]) => void;
  getCoordinate: (position: number | string) => number[];
  updateCoordinate: (position: number | string, lng: number, lat: number) => void;
  removeCoordinate: (position: number | string) => void;
  ctx: { map: mapboxgl.Map; api: { setFeatureProperty: (featureId: string, type: string, value: unknown) => void } };
}

export interface IDirectSelectState {
  selectedCoordPaths: string[];
  line: IBaseShape<GeoJSON.Feature<GeoJSON.Polygon>, GeoJSON.Feature<GeoJSON.LineString>>;
  feature: IShapeFeature;
}

export type TLineGeojson = GeoJSON.Feature<GeoJSON.LineString, IBaseShapeProperties>;
export interface ICircleShapeMode extends DrawCustomModeThis {
  clickAnywhere: (state: ILineShapeState, event: IShapeBaseEvent) => void;
  onStop: (state: ILineShapeState) => void;
  toDisplayFeatures: (
    state: ILineShapeState,
    geojson: TLineGeojson,
    display: (geojson: GeoJSON.Feature) => void,
  ) => void;
}

export interface IRegtangleShapeMode {
  onSetup?: (this: DrawCustomModeThis) => { rectangle: DrawFeature };
  onClick?: (this: DrawCustomModeThis, state: IRegtangleShapeState, event: IShapeBaseEvent) => void;
  onMouseMove?: (this: DrawCustomModeThis, state: IRegtangleShapeState, event: IShapeBaseEvent) => void;
  onStop?: (this: DrawCustomModeThis, state: IRegtangleShapeState) => void;
  onTrash?: (this: DrawCustomModeThis, state: IRegtangleShapeState) => void;
  toDisplayFeatures?: (
    this: DrawCustomModeThis,
    state: IRegtangleShapeState,
    geojson: GeoJSON.Feature<GeoJSON.Polygon, IBaseShapeProperties>,
    display: (geojson: GeoJSON.Feature) => void,
  ) => void;
}

export interface ILineDistanceShapeMode extends DrawCustomModeThis {
  onTrash: (state: ILineShapeState) => void;
  onStop: (state: ILineShapeState) => void;
  toDisplayFeatures: (
    state: ILineShapeState,
    geojson: GeoJSON.Feature<GeoJSON.LineString, IBaseShapeProperties>,
    display: (geojson: GeoJSON.Feature) => void,
  ) => void;
}

export interface IDirectSelectShapeMode extends DrawCustomMode {
  getSelected: () => DrawFeature[];
  dragVertex: (state: IDirectSelectState, event: IShapeBaseEvent) => void;
}
