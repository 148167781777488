import mapboxgl from 'mapbox-gl';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
  polygonSelectionPaint,
  polygonSelectionOutlinePaint,
  selectionLabelLayout,
  selectionLabelPaint,
} from '@/utils/layerStyle';
import useSelectionStore from '@/stores/selection';

import { TMapLayer, LayerTypePrefix, LayerIdPrefixes, ISource } from '@/types/map';
import { ISelectionFeature, SelectionType, IFeatureForDisplay, LegendGeometry } from '@/types/selection';
import { ILayer } from '@/types/agency';
import { IShape } from '@/types/shape';
import { stringifyWkt } from './shapes/common';
import { GeometrySelection } from '@acst/missioninsite-apiservice/apiservice_pb';

export const prepareFeaturesForDisplay = (features: ISelectionFeature[]): IFeatureForDisplay[] => {
  const layerFeatureIdMap = mapValues(groupBy(features, 'layer.id'), val => val.map(v => v.id));

  return Object.keys(layerFeatureIdMap).map(key => ({ layerId: key, featureIds: layerFeatureIdMap[key] }));
};

export const prepareSelectionFeature = ({
  feature,
  layer,
  type,
}: {
  feature: mapboxgl.MapboxGeoJSONFeature;
  layer: ILayer;
  type: SelectionType;
}): ISelectionFeature => {
  return {
    id: feature.id as number,
    type,
    name: `${layer.title} - ${feature.properties?.[layer.defaultLabel ?? 'name']}`,
    layer: { id: layer.id, name: layer.name, source: layer.source },
  };
};

export const getSelectionLayerIds = (layerId: string) => {
  return [
    `${LayerIdPrefixes.mi}:${layerId}:${LayerIdPrefixes.selection}:${LayerTypePrefix.fill}`,
    `${LayerIdPrefixes.mi}:${layerId}:${LayerIdPrefixes.selection}:${LayerTypePrefix.line}`,
    `${LayerIdPrefixes.mi}:${layerId}:${LayerIdPrefixes.selection}:${LayerTypePrefix.symbol}`,
  ];
};

export const prepareSelectionLayers = (layer: ILayer, source: ISource): TMapLayer[] => {
  const layers: TMapLayer[] = [];

  layers.push({
    id: `${LayerIdPrefixes.mi}:${layer.id}:${LayerIdPrefixes.selection}:${LayerTypePrefix.fill}`,
    type: 'fill',
    source: source.id,
    paint: polygonSelectionPaint(),
    layout: {},
    'source-layer': source.type === 'vector' ? source.layer : '',
    filter: ['in', 'id', ''],
  });

  layers.push({
    id: `${LayerIdPrefixes.mi}:${layer.id}:${LayerIdPrefixes.selection}:${LayerTypePrefix.line}`,
    type: 'line',
    source: source.id,
    paint: polygonSelectionOutlinePaint(),
    layout: {},
    'source-layer': source.type === 'vector' ? source.layer : '',
    filter: ['in', 'id', ''],
  });

  layers.push({
    id: `${LayerIdPrefixes.mi}:${layer.id}:${LayerIdPrefixes.selection}:${LayerTypePrefix.symbol}`,
    type: 'symbol',
    source: source.id,
    layout: selectionLabelLayout(layer.defaultLabel),
    'source-layer': source.type === 'vector' ? source.layer : '',
    paint: selectionLabelPaint(),
    filter: ['in', 'id', ''],
  });

  return layers;
};

export function legendGeometry(data?: [Array<ISelectionFeature>, Array<IShape>]): LegendGeometry {
  const selectionStore = useSelectionStore();
  const [features, shapes] = data ?? [selectionStore.features, selectionStore.validShapes];

  const reqFeatures = features.map(feature => ({
    featureId: feature.id.toString(),
    layerId: feature.layer.source.layer,
  }));

  const reqShapes = shapes.map(shape => stringifyWkt(shape.geometry));

  return { features: reqFeatures, shapes: reqShapes };
}

export function convertToGeometrySelection({
  features,
  shapes,
}: {
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
}): GeometrySelection | null {
  if (features.length > 0 || shapes.length > 0) {
    const geometrySelection = new GeometrySelection();
    if (features.length) {
      const featuresList = features.map(feature => {
        const layer = new GeometrySelection.LayerSelection();
        layer.setFeatureid(feature.featureId);
        layer.setLayerid(feature.layerId);
        return layer;
      });
      geometrySelection.setShapesList(featuresList);
    }

    geometrySelection.setShapegeomsList(shapes || []);

    return geometrySelection;
  }
  return null;
}
